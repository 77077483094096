<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form ref="leaveForm" @submit.prevent="saveLeave">
            <v-row dense>
                <v-col cols="12" md="3" style="height: 268px">
                    <v-combobox label="Employee" dense outlined hide-details v-model="employee"
                        :items="$store.getters['employee/employees']"
                        :loading="$store.getters['employee/loadingEmployees']"
                        @focus="$store.dispatch('employee/getEmployees')" item-text="display_name" item-value="id"
                        class="mb-1 custom-form" :rules="[() => !!leave.employee_id || '']"
                        :error-messages="validation_errors.employee_id"></v-combobox>
                    <v-card :dark="employee ? true : false" :color="employee ? 'primary' : '#ECEFF1'" height="100%"
                        :elevation="1">
                        <v-card-text class="text-center px-2 py-4">
                            <v-avatar color="deep-purple darken-4" size="120" class="mb-4">
                                <v-img v-if="employee" :src="$store.state.host + employee.image"></v-img>
                                <v-img v-else src=""></v-img>
                            </v-avatar>
                            <h5>
                                {{ employee ? employee.emp_id : 'Employee ID' }}
                            </h5>
                            <h1 class="subtitle-2">
                                {{ employee ? employee.name : 'Employee Name' }}
                            </h1>
                            <p class="caption mb-0">
                                {{ employee ? employee.designation_name : 'Designation' }},
                                {{ employee ? employee.department_name : 'Department' }}
                            </p>
                            <p class="caption mb-0">
                                {{ employee ? employee.phone : 'Phone' }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                    <v-card outlined tile>
                        <v-date-picker v-model="dates" multiple width="100%" :elevation="1"
                            class="leave__date__picker"></v-date-picker>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Leave Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="py-1 pb-3">
                            <v-row>
                                <v-col cols="12" class="py-0">Application Date</v-col>
                                <v-col cols="12" class="py-0 pb-1">
                                    <v-menu max-width="290" v-model="menu" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field dense readonly outlined hide-details v-on="on"
                                                :rules="[(v) => !!v]" v-model="leave.application_date"></v-text-field>
                                        </template>
                                        <v-date-picker no-title v-model="leave.application_date"
                                            @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-0">Total Days</v-col>
                                        <v-col cols="12" class="py-0 pb-1">
                                            {{ dates.length }}
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-0">Joining Date</v-col>
                                        <v-col cols="12" class="py-0 pb-1">
                                            <div class="joining_date" v-text="employee ? formatDate(employee.joining_date) : ''">
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">Leave Type</v-col>
                                <v-col cols="12" class="py-0 pb-1">
                                    <v-combobox dense outlined hide-details item-text="name" item-value="id"
                                        :items="leave_types" :loading="$store.getters['leave/loadingLeaveTypes']"
                                        :rules="[() => !!leave.leave_type_id || '']"
                                        :error-messages="validation_errors.leave_type_id"
                                        v-model="selectedLeaveType"></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">Remarks</v-col>
                                <v-col cols="12" class="py-0">
                                    <v-textarea dense outlined hide-details v-model="leave.remarks"
                                        rows="2"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col cols="12" md="12" class="text-right">
                            <v-btn height="30px" dark color="light-blue darken-2 mr-1" :elevation="1"
                                type="submit">Save</v-btn>
                            <v-btn height="30px" dark color="deep-orange" :elevation="1"
                                @click="resetForm">Cancel</v-btn>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-form>

        <v-row>
            <v-col md="6">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Leave Summary</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pa-0">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th v-for="type in leave_types" :key="type.id">{{ type.name }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Total</td>
                                        <td v-for="type in leave_types" :key="type.id">{{ type.total_leave }}</td>
                                    </tr>
                                    <tr v-if="leaveSummary.length > 0">
                                        <td>Taken</td>
                                        <td v-for="(summary, sl) in leaveSummary" :key="sl">{{ summary.taken_leave }}
                                        </td>
                                    </tr>
                                    <tr v-if="leaveSummary.length > 0">
                                        <td>Balance</td>
                                        <td v-for="(type, sl) in leave_types" :key="type.id">{{ type.total_leave -
                                            leaveSummary[sl].taken_leave }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="6">
                <v-data-table dense class="custom-data-table" :elevation="1" :headers="leaveHeaders" :items="leaves"
                    :loading="loading">
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Previous Leave Record</v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon small color="primary" v-on="on" @click="showInvoice(item.id)">mdi-file</v-icon>
                            </template>
                            <span>Invoice</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="primary" v-on="on"
                                    @click="editLeave(item)">mdi-circle-edit-outline</v-icon>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="error" v-on="on"
                                    @click="deleteLeave(item.id)">mdi-delete-circle-outline</v-icon>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
import moment from 'moment';
export default {
    mixins: [utility],
    data() {
        return {
            employee: null,
            dates: [],
            leave: {
                id: null,
                employee_id: null,
                application_date: this.toISOLocal().substr(0, 10),
                total_leave: 0,
                leave_type_id: null,
                remarks: '',
            },
            loading: false,
            leaves: [],
            leave_types: [],
            selectedLeaveType: null,
            validation_errors: {},
            leaveSummary: [],
            previous_leave: 0,
            leaveHeaders: [
                { text: 'Sl', value: 'sl' },
                { text: 'Application Date', value: 'application_date', sortable: false },
                { text: 'Leave Type', value: 'leave_type_name', sortable: false },
                { text: 'From Date', value: 'from_date', sortable: false },
                { text: 'To Date', value: 'to_date', sortable: false },
                { text: 'Total Days', value: 'total_leave', sortable: false },
                { text: 'Remarks', value: 'remarks', sortable: false },
                { text: 'Action', value: 'actions' },
            ],
            menu: false,
        }
    },

    watch: {
        selectedLeaveType(type) {
            this.leave.leave_type_id = null;
            if (type == null) return;
            this.leave.leave_type_id = type.id;
        },
        employee(employee) {
            this.leave.employee_id = null;
            this.leaveSummary = [];
            this.leaves = [];
            if (employee == null || employee.id == undefined) return;
            this.leave.employee_id = employee.id;
            this.getLeaveSummary();
            this.getLeaves(employee.id);
        },

        dates(dates) {
            this.leave.total_leave = dates.length;
        }
    },

    async created() {
        await this.$store.dispatch('leave/getLeaveTypes');
        this.leave_types = this.$store.getters['leave/leaveTypes'];
        this.$store.commit('leave/addLeaves', []);

        if (this.$route.params.id) {
            let leave = await this.$store.dispatch('leave/getLeaves', {
                apiParams: {
                    id: this.$route.params.id
                },
                options: {
                    returnData: true
                }
            })

            this.editLeave(leave[0]);
            this.employee = leave[0].employee;
        }
    },

    methods: {
        formatDate(date){
            return moment(date).format("DD-MM-YYYY");
        },
        async getLeaves(employeeId) {
            this.loading = true;

            await this.$store.dispatch('leave/getLeaves', {
                apiParams: {
                    employeeId: employeeId,
                    current_year: true
                }
            })
            this.leaves = this.$store.getters['leave/leaves'];

            await this.$store.dispatch('leave/getLeaveTypes', {
                apiParams: {
                    employeeId: employeeId
                }
            });
            this.leave_types = this.$store.getters['leave/leaveTypes'];

            let totalTaken = 0;
            this.leaveSummary.forEach((item, ind) => {
                totalTaken += parseFloat(Object.values(item)[0]);
            })

            this.previous_leave = this.leave_types.reduce((prev, curr) => { return prev + parseFloat(curr.total_leave) }, 0) - totalTaken;
            this.loading = false;
        },
        async getLeaveSummary() {
            let summary = await this.$store.dispatch('leave/getLeaveSummary',
                {
                    employeeId: this.employee.id,
                    data: {
                        current_year: true
                    }
                }
            );
            this.leaveSummary = summary;
        },

        async saveLeave() {

            if (!this.$refs.leaveForm.validate()) {
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            if (this.dates.length == 0) {
                alert('Date Required!');
                return;
            }
            this.leave.previous_leave = this.previous_leave;
            let leaveData = {
                leave: this.leave,
                leaveDates: this.dates,
            }

            let resObj = await this.$store.dispatch('leave/saveLeave', leaveData);

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }

            if (resObj.isSuccess) {
                this.$store.commit('leave/addLeaves', []);
                this.resetForm();

                if (this.$route.path !== "/leave") {
                    this.$router.push('/leave');
                }
            }
        },

        editLeave(leave) {
            this.dates = leave.leave_dates.map(ld => {
                return ld.leave_date;
            });

            this.selectedLeaveType = leave.leave_type;

            Object.keys(this.leave).forEach(key => this.leave[key] = leave[key]);
        },

        async deleteLeave(id) {
            let deleteConf = confirm('Are you sure?');
            if (deleteConf == false) {
                return;
            }
            let isSuccess = await this.$store.dispatch('leave/deleteLeave', id);
            if (isSuccess) {
                this.getLeaveSummary();
                this.getLeaves(this.employee.id);
            }
        },

        resetForm() {
            this.employee = null;
            this.dates = [];
            this.leave = {
                id: null,
                application_date: this.toISOLocal().substr(0, 10),
                employee_id: null,
                total_leave: 0,
                leave_type_id: null,
                remarks: '',
            }
            this.selectedLeaveType = null;
            this.validation_errors = {};
            this.$refs.leaveForm.resetValidation();
        },


        showInvoice(id) {
            window.open(`/leave_invoice/${id}`, '_blank');
        }
    },
}
</script>

<style lang="scss">
.leave__date__picker {
    .v-date-picker-header {
        padding: 0 16px;
    }

    .v-picker__title {
        padding: 8px;
    }

    .v-date-picker-title__date {
        font-size: 20px !important;
    }

    .v-date-picker-table {
        position: relative;
        padding: 0 0;
        height: 190px !important;
    }

    .v-date-picker-table--date .v-btn {
        height: 28px;
        width: 28px;
    }
}

.joining_date {
    border: 1px solid gray !important;
    padding: 0 7px !important;
    border-radius: 6px !important;
    height: 33px !important;
    display: flex !important;
    align-items: center !important;
    color: black !important;
}
</style>